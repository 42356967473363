.legal-button {
  display: block;
  text-decoration: none;
  background-color: white;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid #ECEDFA;
  box-shadow: 4px 4px 8px rgba(115, 116, 145, 0.09);
  width: 268px;
  border-radius: 10px;

  color: $gray-600;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.139286px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border: 1px solid rgba(118, 109, 244, 0.42);
    background-color: scale-color(#EDECFA, $lightness: 50%);
    text-decoration: none;
    color: unset;
  }

  &--active {
    border: 1px solid rgba(118, 109, 244, 0.42);
    background: #EDECFA;

    color: #746DEC;
  }
}

.legal-content {
  padding-top: 48px;
  padding-left: 45px;
  padding-right: 45px;

  & h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    color: #4B4B63;
  }

  & h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $gray-600;
    display: inline;
    vertical-align: top;
  }

  & h2 + p {
    display: inline;
    vertical-align: top;

    &:after {
      content: '';
      display: block;
      margin-bottom: 1.25rem;
    }
  }

  & dt, dd  {
    box-sizing: border-box;
  }

  dt {
    float: left;
    width: 30%;
    min-width: 200px;
    padding-bottom: 0;
    color: $gray-600;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  dd {
    margin-left: max(30%, 200px);

    &::first-letter {
      text-transform: capitalize;
    }
  }

  table {
    margin-bottom: 1.25rem;
    border-collapse: collapse;

    tbody tr {
      border-top: 1px solid #E6E6F2;
      border-bottom: 1px solid #E6E6F2;
    }

    td {
      vertical-align: top;
    }
  }
}

.legal-content .privacy-content {
  h2 {
    display: inline-block;
  }
  @import 'privacy-policy/reset';
  @import 'privacy-policy/base';
  @import 'privacy-policy/common';
  @import 'privacy-policy/privacy';
}

.legal-title {
  color: $primary;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  display: inline-block;
  margin-bottom: 32px;
}
