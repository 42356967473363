/* Common styles for both modal and privacy */

.privacy .act-checker {
  display: none
}

.privacy__title {
  text-align: center;
  color: $primary;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  display: inline-block;
}

.privacy__textual {
  font-size: 12px;
  line-height: 18px;
  color: $gray-600;
  margin: 15px 0 30px;
  text-align: center
}

.privacy__open-all {
  position: absolute;
  right: 0;
  top: -30px
}

.privacy__item {
  position: relative;
  border-top: 1px solid #E6E6F2;
  padding-bottom: 15px
}

.privacy__item-icon {
  fill: transparent;
  stroke: $gray-600;
  vertical-align: text-bottom;
  display: inline-block;
  margin-right: 10px;
  width: 25px;
  height: 29px;
}

.privacy__item .icon {
  width: 25px;
  height: 29px
}

.privacy__item-title {
  color: #4B4B63;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 15px 5px;
  font-weight: 400;
  vertical-align: top;
  padding-top: 15px
}

.privacy__item-block {
  padding-bottom: 15px;
  font-size: 13px;
  line-height: 18px;
  color: $gray-600;
  -webkit-transition: .3s;
  transition: .3s;
}

.privacy__item-block p {
  line-height: 16px;
  font-size: 13px;
}

.privacy__item-block.act-slide .data-collect__item>div>:last-child, .privacy__item-block:last-child {
  margin-bottom: 0
}

.privacy__item-block.act-slide {
  margin-left: 0;
  padding-bottom: 0;
  padding-left: 40px;
  border-left: 1px solid #dbdde7;
  overflow-y: hidden;
  max-height: 0;
  -webkit-transition: .5s;
  transition: .5s
}

.privacy__item-block.act-slide .privacy__item-i {
  margin: 0
}

.privacy__item-block ul {
  list-style: disc outside;
  padding-left: 20px
}

.privacy__item-block a {
  color: inherit
}

.privacy__item-i {
  margin: 0 40px
}

.data-collect__item>div>p, .privacy__item-i>* {
  margin-bottom: 15px
}

.privacy__item-i>:last-child {
  margin-bottom: 0
}

.privacy .act-checker:checked~.privacy__more {
  display: none
}

.privacy .act-checker:checked~.privacy__less {
  display: inline-block
}

.privacy .act-checker:checked~.act-slide {
  margin-top: 15px;
  margin-bottom: 15px;
  max-height: 3000px
}

.privacy .act-checker:checked~.act-smooth {
  background: #fbfbfc
}

.privacy-toggle {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s
}

.privacy-toggle i {
  text-decoration: underline;
  font-style: normal
}

.privacy__more {
  color: #766DF4
}

.privacy__more:hover {
  color: #615BC5
}

.privacy__less {
  color: #766DF4;
  display: none
}

.privacy__less:hover {
  color: #615BC5
}

.data-scheme__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.data-scheme__top .privacy__item-icon {
  margin-right: 0;
  stroke: none;
}

.data-scheme__top p {
  margin-bottom: 0;
}

.privacy-modal .privacy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.privacy-modal .privacy__modal-logo {
  position: absolute;
  left: 0;
  top: 0;
}

.logo {
  font-size: 37px;
  text-align: center;
}

.icon-logo {
  width: 2.58em;
  height: 1em;
  fill: #1a5069
}

.data-scheme__top-left, .data-scheme__top-right {
  color: #76C099;
  fill: #76C099;
  text-align: center;
  width: 50px
}

.data-scheme__top-right {
  color: #0090DA;
  fill: #0090DA
}

.data-scheme__wrap {
  border-left: 2px solid #76C099;
  margin-left: 25px;
  padding-left: 50px
}

.data-scheme__list {
  border-left: 2px solid #0090DA;
  padding: 10px 0 10px 25px
}

p.data-scheme__item {
  position: relative;
  line-height: 30px;
  margin-bottom: 0;
}

.data-scheme__item.bull-blue:before, .data-scheme__item.bull-green:after {
  content: '';
  position: absolute;
  left: -36px;
  top: 50%;
  margin-top: -11px;
  display: block;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid #0090DA;
  background: #fff
}

.data-scheme__item.bull-green:after {
  left: -88px;
  border: 2px solid #76C099
}

.privacy-modal .data-scheme__item.bull-blue:before {
  left: -34px
}

.privacy-modal .data-scheme__item.bull-green:after {
  left: -88px
}

.data-collect__item {
  vertical-align: top;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.data-collect__item:last-child, .data-collect__item>div>p:last-child, .highlight-box>:last-child {
  margin-bottom: 0
}

.data-collect__item-icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25px;
  -ms-flex: 0 0 25px;
  flex: 0 0 25px;
  margin-right: 10px;
  vertical-align: top;
  fill: transparent;
  stroke: #4B4B63;
}

.data-collect__title {
  font-size: 14px;
  line-height: 18px;
  color: #4B4B63;
}

.data-collect__tags {
  font-size: 10px;
  line-height: 12px;
  color: #495355
}

.data-collect__tags span {
  margin: 0 5px;
  padding: 0 4px;
  padding-bottom: 2px;
  cursor: pointer;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #D9DBFC;
}

@media only screen and (max-width:767px) {
  .trust-content__logos {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
  }
  .privacy-modal .privacy {
    display: block
  }
  .privacy-modal .privacy__modal-logo {
    position: static
  }
  .privacy-modal .privacy__modal-column {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1 1
  }
  .privacy__item-block.act-slide, .result-content__textual p {
    padding-left: 20px
  }
  .columns__main {
    padding: 0 10px
  }
  .privacy__title {
    font-size: 26px
  }
  .privacy__item-i {
    margin: 0 20px
  }
  .privacy .act-checker:checked~.act-slide {
    max-height: 4500px
  }
  .privacy__table tr {
    display: block
  }
  .privacy__table td {
    display: block;
    font-size: 11px
  }
  .privacy__table td:before {
    content: attr(data-label);
    font-weight: 700;
    text-transform: uppercase;
    display: block
  }
  .privacy-modal .privacy__title-link {
    display: block;
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}