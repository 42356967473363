a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline
}

fieldset, img {
  margin: 0;
  padding: 0;
  vertical-align: baseline
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

a img, fieldset, img {
  border: none
}

input[type=email], input[type=tel], input[type=text], textarea {
  -webkit-appearance: none
}

button, input[type=submit] {
  cursor: pointer
}

button::-moz-focus-inner, input[type=submit]::-moz-focus-inner {
  padding: 0;
  border: 0
}

textarea {
  overflow: auto
}

button, input {
  margin: 0;
  padding: 0;
  border: 0
}

a, a:focus, button, div, h1, h2, h3, h4, h5, h6, input, select, span, textarea {
  outline: none
}

ol, ul {
  list-style-type: none
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%
}

*, :after, :before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit
}